(function($) {
    "use strict";

    //1.Hide Loading Box (Preloader)
    function handlePreloader() {
        if ($('.preloader').length) {
            $('.preloader').delay(200).fadeOut(500);
        }
    }

    //6.Scroll to a Specific Div
    if ($('.scroll-to-target').length) {
        $(".scroll-to-target").click('click', function() {
            var target = $(this).attr('data-target');
            // animate
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 1000);

        });
    }

    //20.Contact Form Validation
    if ($("#contact-form").length) {
        $("#contact-form").validate({
            submitHandler: function(form) {
                var form_btn = $(form).find('button[type="submit"]');
                var form_result_div = '#form-result';
                $(form_result_div).remove();
                form_btn.before('<div id="form-result" class="alert alert-success" role="alert" style="display: none;"></div>');
                var form_btn_old_msg = form_btn.html();
                form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
                $(form).ajaxSubmit({
                    dataType: 'json',
                    success: function(data) {
                        if (data.status = 'true') {
                            $(form).find('.form-control').val('');
                        }
                        form_btn.prop('disabled', false).html(form_btn_old_msg);
                        $(form_result_div).html(data.message).fadeIn('slow');
                        setTimeout(function() { $(form_result_div).fadeOut('slow') }, 6000);
                    }
                });
            }
        });
    }

    //22.Appoinment Form Validation
    if ($("#appoinment-form").length) {
        $("#appoinment-form").validate({
            submitHandler: function(form) {
                var form_btn = $(form).find('button[type="submit"]');
                var form_result_div = '#form-result';
                $(form_result_div).remove();
                form_btn.before('<div id="form-result" class="alert alert-success" role="alert" style="display: none;"></div>');
                var form_btn_old_msg = form_btn.html();
                form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
                $(form).ajaxSubmit({
                    dataType: 'json',
                    success: function(data) {
                        if (data.status = 'true') {
                            $(form).find('.form-control').val('');
                        }
                        form_btn.prop('disabled', false).html(form_btn_old_msg);
                        $(form_result_div).html(data.message).fadeIn('slow');
                        setTimeout(function() { $(form_result_div).fadeOut('slow') }, 6000);
                    }
                });
            }
        });
    }

    //26.Date picker
    function datepicker() {
        if ($('#datepicker').length) {
            $('#datepicker').datepicker();
        };
    }

    //27.Select menu 
    function selectDropdown() {
        if ($(".selectmenu").length) {
            $(".selectmenu").selectmenu();

            var changeSelectMenu = function(event, item) {
                $(this).trigger('change', item);
            };
            $(".selectmenu").selectmenu({ change: changeSelectMenu });
        };
    }

    //28.Elements Animation
    if ($('.wow').length) {
        var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset: 0, // distance to the element when triggering the animation (default is 0)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true // act on asynchronously loaded content (default is true)
        });

        wow.init();

    }

    // Hero Slider

    $('.hero-slider').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 300,
        dots: true,
        arrows: true,
        fade: true,
        responsive: [{
            breakpoint: 600,
            settings: {
                arrows: false
            }
        }]
    });

    // Item Slider
    $('.items-container').not('.slick-initialized').slick({
        infinite: true,
        arrows: true,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    });









    // Testimonial Slider
    $('.testimonial-carousel').not('.slick-initialized').slick({
        infinite: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 2,
        dots: true,
        slidesToScroll: 2,
        responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 525,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // FancyBox Video
    $('[data-fancybox]').fancybox({
        youtube: {
            controls: 0,
            showinfo: 0
        },
        vimeo: {
            color: 'f00'
        }
    });

    /* ========================When document is loaded, do===================== */
    $(window).on('load', function() {
        // add your functions
        (function($) {
            handlePreloader();
            datepicker();
        })(jQuery);
    });


})(window.jQuery);

$(document).ready(function() {
    $("#tableSearch").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});


function handleMask(event, mask) {
    with(event) {
        stopPropagation()
        preventDefault()
        if (!charCode) return
        var c = String.fromCharCode(charCode)
        if (c.match(/\D/)) return
        with(target) {
            var val = value.substring(0, selectionStart) + c + value.substr(selectionEnd)
            var pos = selectionStart + 1
        }
    }
    var nan = count(val, /\D/, pos)
    val = val.replace(/\D/g, '')
    var mask = mask.match(/^(\D*)(.+9)(\D*)$/)
    if (!mask) return
    if (val.length > count(mask[2], /9/)) return
    for (var txt = '', im = 0, iv = 0; im < mask[2].length && iv < val.length; im += 1) {
        var c = mask[2].charAt(im)
        txt += c.match(/\D/) ? c : val.charAt(iv++)
    }
    with(event.target) {
        value = mask[1] + txt + mask[3]
        selectionStart = selectionEnd = pos + (pos == 1 ? mask[1].length : count(value, /\D/, pos) - nan)
    }

    function count(str, c, e) {
        e = e || str.length
        for (var n = 0, i = 0; i < e; i += 1)
            if (str.charAt(i).match(c)) n += 1
        return n
    }
}



function LimpiarMensaje() {
    var m = document.getElementById('cuil');
    if (m != null) {
        m.className = 'displayNone';
        m.innerHTML = '';
    }
}